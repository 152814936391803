export class Lesson {
  constructor(public id: string,
              public tutorID: string,
              public tutorName: string,
              public studentOrGroup: string,
              public format: string,
              public date: string,
              public time: string,
              public subject: string,
              public duration: string,
              public regularity: string,
              public auditory: string,
              public remarks: string) {
    this.id = id;
    this.tutorName = tutorName;
    this.tutorID = tutorID;
    this.studentOrGroup = studentOrGroup;
    this.format = format;
    this.date = date;
    this.time = time;
    this.subject = subject;
    this.duration = duration;
    this.regularity = regularity;
    this.auditory = auditory;
    this.remarks = remarks;
  }
}
