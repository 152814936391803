import {Employee} from '../model/Employee';
import {Injectable} from '@angular/core';
@Injectable()
export class EmployeeService {
  public  employee: Employee;
  constructor() {}

 getEmployee(): Employee {
   return  JSON.parse(localStorage.getItem('employee'));
 }
  setEmployee(employee: Employee) {
    this.employee = employee;
    localStorage.setItem('employee', JSON.stringify(this.employee));
  }
}
