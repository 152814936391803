import {Component, Inject, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Lesson} from '../../model/Lesson';
import {HttpService} from '../../services/http-service';
import {NgbDate, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ScheduleService} from '../../services/schedule-service';
import {Employee} from '../../model/Employee';
import {EmployeeService} from '../../services/employee-service';
import {ConflictService} from '../../services/conflict-service';
import {MessageService} from 'primeng/api';
import {AuthService} from '../../services/AuthService';

@Component({
  selector: 'app-edit-lesson',
  templateUrl: './edit-lesson.component.html',
  styleUrls: ['./edit-lesson.component.css']
})
export class EditLessonComponent implements OnInit {
  @Input() lesson: Lesson;
  @Input() isEdit: boolean;
  lessonForm: FormGroup;
  selectedDate: any;
  employee: Employee;
  myDatePickerOptions: any;
  date: Date;
  amoutOfNumbers = 0;
  dates: Date[];
  rangeDates: Date[];

  minDate: Date;

  maxDate: Date;

  es: any;

  invalidDates: Array<Date>;
  time: string;
  showTime = new Date();
  durations = [{name: '1.0'},
{name: '1.5'},
{name: '2.0'},
{name: '2.5'},
{name: '3.0'},
    {name: '3.5'},
    {name: '4.0'}] ;

    formats = [{name: 'онлайн'},
    {name: 'дома'},
    {name: 'ГК или СК'}];

  subjects = [{name: 'Математика'},
    {name: 'Физика'},
    {name: 'Химия'},
    {name: 'Информатика'}] ;
  selectedDuration = {name: '2.0'};
  selectedSubject = {name: 'Математика'};
  selectedFormat = {name: 'онлайн'};
  public mask = {
    guide: true,
    showMask: true,
    mask: [/\d/, /\d/, ':', /\d/, /\d/]
  };

  constructor(private http: HttpService,  private modalService: NgbModal,
              private scheduleService: ScheduleService,
              private conflictService: ConflictService, private messageService: MessageService,
              private authService: AuthService,
              private employeeService: EmployeeService) {
    this.es = {
      firstDayOfWeek: 1,
      dayNames: [ 'domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado' ],
      dayNamesShort: [ 'dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb' ],
      dayNamesMin: [ 'D', 'L', 'M', 'X', 'J', 'V', 'S' ],
      monthNames: [ 'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre' ],
      monthNamesShort: [ 'ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic' ],
      today: 'Hoy',
      clear: 'Borrar'
    };

    this.showTime.setHours(0);
    this.showTime.setMinutes(0);
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    const prevMonth = (month === 0) ? 11 : month - 1;
    const prevYear = (prevMonth === 11) ? year - 1 : year;
    const nextMonth = (month === 11) ? 0 : month + 1;
    const nextYear = (nextMonth === 0) ? year + 1 : year;
    this.minDate = new Date();
    this.minDate.setMonth(prevMonth);
    this.minDate.setFullYear(prevYear);
    this.maxDate = new Date();
    this.maxDate.setMonth(nextMonth);
    this.maxDate.setFullYear(nextYear);

    const invalidDate = new Date();
    invalidDate.setDate(today.getDate() - 1);
    this.invalidDates = [today, invalidDate];

    this.lessonForm = new  FormGroup({
      studentName: new FormControl( null, [Validators.required]),
      subject: new FormControl(this.selectedSubject, [Validators.required]),
      duration: new FormControl(this.selectedDuration, [Validators.required]),
      regularity: new FormControl( null),
      date: new FormControl( null, [Validators.required]),
      time: new FormControl( null, [Validators.required]),
      format: new FormControl(this.selectedFormat, [Validators.required]),
      remarks: new FormControl(null)
    });
  }

  ngOnInit() {
    if (this.lesson.studentOrGroup) {
      this.lessonForm.setControl('studentName', new FormControl(this.lesson.studentOrGroup, [Validators.required]));
    }
    if (this.lesson.remarks) {
      this.lessonForm.setControl('remarks', new FormControl(this.lesson.remarks));
    }

    if (this.lesson.regularity) {
      this.lessonForm.setControl('regularity', new FormControl(true));
    }
    if (this.lesson.subject) { this.selectedSubject = {name: this.lesson.subject}; }
    if (this.lesson.format) { this.selectedFormat = {name: this.lesson.format}; }
    if (this.lesson.format === 'ГК или СК') { this.selectedFormat = {name: 'ГК или СК'}; }
    if (this.lesson.duration) { this.selectedDuration = {name: this.lesson.duration}; }
    if (this.lesson.date) { this.date = new Date(this.lesson.date); }
    const arr = this.lesson.time.split('-')[0].split(':');
    const hours = arr[0];
    const minutes = arr[1];
    if (this.lesson.time) {
      this.time = hours + ':' + minutes;
    }
  }

  save() {

    let isConflictExist = false;
    if (this.lessonForm.value['studentName']) {
      this.lesson.studentOrGroup = this.lessonForm.value['studentName'];
    }
    if (this.selectedSubject) {
      this.lesson.subject = this.selectedSubject.name;
    }
    if (this.selectedDuration) {
      this.lesson.duration = this.selectedDuration.name;
    }
    if (this.lessonForm.value['regularity']) {
      this.lesson.regularity = this.lessonForm.value['regularity'];
    } else {
      this.lesson.regularity = 'false' ;
    }
   // if (this.lessonForm.value['remarks']) {
      this.lesson.remarks = this.lessonForm.value['remarks'];
  //  }

    let message = '';

    if (this.time) {
      const strTime = this.time.split(':');
      const hours = Number(strTime[0]);
      const minutes = Number(strTime[1]);
      if (hours < 0 || hours > 24 || minutes < 0 || minutes > 59) {
        isConflictExist = true;
        message = 'Время введено неправильно!';
      }
      this.lesson.time = this.time;
    }
    if (this.selectedFormat) {
      this.lesson.format = this.selectedFormat.name;
      if (this.lesson.format === 'ГК или СК') { this.lesson.format = 'ГК или СК'; }
    }


    let strDateRes = '';
    let strDay = this.date.getDate().toString();
    let strMonth = (this.date.getMonth() + 1).toString();
    if (this.date.getMonth() + 1 <= 9) {
      strMonth = '0' + (this.date.getMonth() + 1);
    }
    if (this.date.getDate() <= 9) {
      strDay = '0' + this.date.getDate();
    }
    strDateRes += this.date.getFullYear() + '-' + strMonth + '-' + strDay;
    this.lesson.date = strDateRes;
    const allLessons = this.scheduleService.getLessons();
    allLessons.forEach(next => {
      if (next.id !== this.lesson.id ) {
        if (this.conflictService.hasConflict(next, this.lesson)) {
        isConflictExist = true;
        message = 'На это время уже добавлено занятие!';
       }
      }
    });
    this.employee = JSON.parse(localStorage.getItem('employee'));
    this.lesson.tutorName = this.employee.tutorName;
    if (!isConflictExist) {
      if (this.isEdit) {

        this.lesson.tutorID = this.employee.tutorID;
        this.http.editLesson(this.lesson).subscribe(data => {
          if (data['status'] === 'OK') {
            this.scheduleService.loadSchedule(this.employee.tutorID);
            // this.http.getLessons(this.employee.tutorID).subscribe(lessons => {
            //   this.scheduleService.setLessons(lessons);
            // });
          }
        }, () => {
          this.authService.logOut();
        });
      } else {
        this.lesson.id = null;
        this.employee = JSON.parse(localStorage.getItem('employee'));
        this.lesson.tutorID = this.employee.tutorID;
        this.http.addLesson(this.lesson).subscribe(data => {
          if (data['status'] === 'OK') {
            this.employee = JSON.parse(localStorage.getItem('employee'));
            this.scheduleService.loadSchedule(this.employee.tutorID);
            // this.http.getLessons(this.employee.tutorID).subscribe(lessons => {
            //   this.scheduleService.setLessons(lessons);
            // });
          }
        }, () => {
          this.authService.logOut();
        });
      }
      this.modalService.dismissAll();
    } else {
      this.messageService.add(
        {severity: 'error',
          summary: 'Ошибка',
          detail: message});
      isConflictExist = true;
    }
    this.http.getUser(this.employee.email, this.employee.password).subscribe(user => {
      if (user[0] != null) {
        const employee: Employee = user[0];
        this.employeeService.setEmployee(employee);
      }
    });
  }

  exit() {
    this.modalService.dismissAll();
  }

  onTimeChange(event: any) {
  }
}
