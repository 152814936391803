import {Lesson} from '../model/Lesson';

export class ConflictService {
  public hasConflict(next: Lesson, lesson: Lesson) {

    const arrNext = next.date.split('-');
    const dateNext = new Date(next.date);
    dateNext.setFullYear(Number(arrNext[0]));
    dateNext.setMonth(Number(arrNext[1]));
    dateNext.setDate(Number(arrNext[2]));

    const arrTimeNext: string[] = next.time.split(':');
    const hoursNextBegin: number = Number(arrTimeNext[0]);
    const minutesNextBegin: number = Number(arrTimeNext[1]);
    const durationArrNext = next.duration.split('.');
    const hoursDurationNext = Number(durationArrNext[0]);
    let  minutesDurationNext = Number(durationArrNext[1]);
    if (minutesDurationNext === 5) {
      minutesDurationNext = 30;
    }
    let hoursNextFinish = hoursNextBegin + hoursDurationNext;
    let minutesNextFisish = (minutesNextBegin + minutesDurationNext);
    if (minutesNextFisish >= 60 ) {
      minutesNextFisish = minutesNextFisish - 60;
      hoursNextFinish += 1;
    }
    let dateNextFinish: Date = null;
    if (hoursNextFinish >= 24) {
      dateNextFinish = new Date(dateNext.toDateString());
      dateNextFinish.setDate(dateNextFinish.getDate() + 1);
      hoursNextFinish = hoursNextFinish - 24;
    }

    const arrLesson = lesson.date.split('-');
    const dateLesson = new Date(lesson.date);
    dateLesson.setFullYear(Number(arrLesson[0]));
    dateLesson.setMonth(Number(arrLesson[1]));
    dateLesson.setDate(Number(arrLesson[2]));

    const arrTimeLesson: string[] = lesson.time.split(':');
    const hoursLessonBegin: number = Number(arrTimeLesson[0]);
    const minutesLessonBegin: number = Number(arrTimeLesson[1]);
    const durationArrLesson = lesson.duration.split('.');
    const hoursDurationLesson = Number(durationArrLesson[0]);
    let  minutesDurationLesson = Number(durationArrLesson[1]);
    if (minutesDurationLesson === 5) {
      minutesDurationLesson = 30;
    }
    let hoursLessonFinish = hoursLessonBegin + hoursDurationLesson;
    let minutesLessonFinish = (minutesLessonBegin + minutesDurationLesson);
    if (minutesLessonFinish >= 60 ) {
      minutesLessonFinish = minutesLessonFinish - 60;
      hoursLessonFinish += 1;
    }
    let dateLessonFinish: Date = null;
    if (hoursLessonFinish >= 24) {
      dateLessonFinish = new Date(dateLesson.toDateString());
      dateLessonFinish.setDate(dateLessonFinish.getDate() + 1);
      hoursLessonFinish = hoursLessonFinish - 24;
    }

    const fullDateBeginNext = new Date(dateNext.getFullYear(), dateNext.getMonth() - 1, dateNext.getDate(),
      hoursNextBegin, minutesNextBegin);
    const fullDateBeginLesson = new Date(dateLesson.getFullYear(), dateLesson.getMonth() - 1, dateLesson.getDate(),
      hoursLessonBegin, minutesLessonBegin);
    let fullDateFinishNext = new Date(dateNext.getFullYear(), dateNext.getMonth() - 1, dateNext.getDate(),
      hoursNextFinish, minutesNextFisish);
    let fullDateFinishLesson = new Date(dateLesson.getFullYear(), dateLesson.getMonth() - 1, dateLesson.getDate(),
      hoursLessonFinish, minutesLessonFinish);

  if (dateNextFinish ) {
     fullDateFinishNext = new Date(dateNextFinish.getFullYear(), dateNextFinish.getMonth() - 1, dateNextFinish.getDate(),
      hoursNextFinish, minutesNextFisish);
  }

  if (dateLessonFinish) {
    fullDateFinishLesson = new Date(dateLessonFinish.getFullYear(), dateLessonFinish.getMonth() - 1, dateLessonFinish.getDate(),
      hoursNextFinish, minutesNextFisish);
  }

    const dateObj = new Date();
    const fullDateBeginNextMillis = (Date.parse(fullDateBeginNext.toDateString()) + dateObj.getTimezoneOffset() + hoursNextBegin * 60 * 60 * 1000 + minutesNextBegin * 60 * 1000) / (1000 * 60);
    const fullDateFinishNextMillis = (Date.parse(fullDateFinishNext.toDateString()) + dateObj.getTimezoneOffset() + hoursNextFinish * 60 * 60 * 1000 + minutesNextFisish * 60 * 1000) / (1000 * 60);
    const fullDateBeginLessonMillis = (Date.parse(fullDateBeginLesson.toDateString()) + dateObj.getTimezoneOffset() + hoursLessonBegin * 60 * 60 * 1000 + minutesLessonBegin * 60 * 1000) / (1000 * 60);
    const fullDateFinishLessonMillis = (Date.parse(fullDateFinishLesson.toDateString()) + dateObj.getTimezoneOffset() + hoursLessonFinish * 60 * 60 * 1000 + minutesLessonFinish * 60 * 1000) / (1000 * 60);




    if (fullDateFinishLessonMillis <= fullDateBeginNextMillis ||
      fullDateBeginLessonMillis >= fullDateFinishNextMillis) {
      return false;
    }
    return true;

    //
    //
    // if ((dateLessonFinish && !dateNextFinish) || (dateLessonFinish && dateNextFinish)) {
    //   if (dateNext.getDate() === dateLesson.getDate() &&
    //     dateNext.getMonth() === dateLesson.getMonth() &&
    //     dateNext.getFullYear() === dateLesson.getFullYear()) {
    //     if ((hoursLessonBegin === hoursNextBegin && minutesLessonBegin === minutesNextBegin) ||
    //       this.isInside(hoursLessonBegin, minutesLessonBegin, hoursNextBegin, minutesNextBegin,
    //         hoursNextFinish, minutesNextFisish) || this.isInside(0, 0,
    //         hoursNextBegin, minutesNextBegin,
    //         hoursNextFinish, minutesNextFisish)) {
    //       isConflictExist = true;
    //     }
    //   }
    //   if (dateNext.getDate() === dateLessonFinish.getDate() &&
    //     dateNext.getMonth() === dateLessonFinish.getMonth() &&
    //     dateNext.getFullYear() === dateLessonFinish.getFullYear()) {
    //     if ((0 === hoursNextBegin && 0 === minutesNextBegin) ||
    //       this.isInside(0, 0, hoursNextBegin, minutesNextBegin,
    //         hoursNextFinish, minutesNextFisish) || this.isInside(hoursLessonFinish, minutesLessonFinish,
    //         hoursNextBegin, minutesNextBegin,
    //         hoursNextFinish, minutesNextFisish)) {
    //       isConflictExist = true;
    //     }
    //   }
    // }
    //
    // if (dateNextFinish && !dateLessonFinish) {
    //   if (dateNextFinish.getDate() === dateLesson.getDate() &&
    //     dateNextFinish.getMonth() === dateLesson.getMonth() &&
    //     dateNextFinish.getFullYear() === dateLesson.getFullYear()) {
    //     if ((hoursLessonBegin === 0 && minutesLessonBegin === 0) ||
    //       this.isInside(hoursLessonBegin, minutesLessonBegin, 0, 0,
    //         hoursNextFinish, minutesNextFisish) || this.isInside(hoursLessonFinish, minutesLessonFinish,
    //         0, 0,
    //         hoursNextFinish, minutesNextFisish)) {
    //       isConflictExist = true;
    //     }
    //   }
    //   if (dateNext.getDate() === dateLesson.getDate() &&
    //     dateNext.getMonth() === dateLesson.getMonth() &&
    //     dateNext.getFullYear() === dateLesson.getFullYear()) {
    //     if ((hoursLessonBegin === 0 && minutesLessonBegin === 0) ||
    //       this.isInside(hoursLessonBegin, minutesLessonBegin, hoursNextBegin, minutesNextBegin,
    //         24, minutesNextFisish) || this.isInside(hoursLessonFinish, minutesLessonFinish,
    //         hoursNextBegin, minutesNextBegin,
    //         24, minutesNextFisish)) {
    //       isConflictExist = true;
    //     }
    //   }
    // }
    //
    //
    // if (!dateNextFinish && !dateLessonFinish && dateNext.getDate() === dateLesson.getDate() &&
    //   dateNext.getMonth() === dateLesson.getMonth() &&
    //   dateNext.getFullYear() === dateLesson.getFullYear()) {
    //   if ((hoursLessonBegin === hoursNextBegin && minutesLessonBegin === minutesNextBegin) ||
    //     this.isInside(hoursLessonBegin, minutesLessonBegin, hoursNextBegin, minutesNextBegin,
    //       hoursNextFinish, minutesNextFisish) || this.isInside(hoursLessonFinish, minutesLessonFinish,
    //       hoursNextBegin, minutesNextBegin,
    //       hoursNextFinish, minutesNextFisish)) {
    //     isConflictExist = true;
    //   }
    // }
   // return isConflictExist;
  }
  //
  // public isOutside(hoursLessonBegin: number, minutesLessonBegin: number,
  //                  hoursNextBegin: number, minutesNextBegin: number,
  //                  hoursNextFinish: number, minutesNextFinish: number,
  //                  hoursLessonFinish: number, minutesLessonFinish: number) {
  //    const sumBeginLessonTime = hoursLessonBegin * 60 + minutesLessonBegin;
  //    const sumFinishLessonTime = hoursLessonFinish * 60 + minutesLessonFinish;
  //    const sumBeginNextTime = hoursNextBegin * 60 + minutesNextBegin;
  //    const sumFinishNextTime = hoursNextFinish * 60 + minutesNextFinish;
  //    if (sumBeginNextTime !== 0 && sumFinishNextTime !== 0) {
  //       if (sumBeginLessonTime < sumBeginNextTime && sumFinishLessonTime > sumFinishNextTime) {
  //         return true;
  //       }
  //    }
  //    if (sumBeginNextTime === 0) {
  //
  //    }
  // }

  // public isInside(hoursLesson: number, minutesLesson: number,
  //                  hoursNextBegin: number, minutesNextBegin: number,
  //                  hoursNextFinish: number, minutesNextFinish: number): boolean {
  //   if ((hoursLesson > hoursNextBegin && hoursLesson < hoursNextFinish) ||
  //     ((hoursLesson === hoursNextBegin && minutesLesson > minutesNextBegin && (hoursNextFinish > hoursLesson ||
  //       (hoursNextFinish === hoursLesson && minutesLesson < minutesNextFinish)))) ||
  //     (hoursLesson === hoursNextFinish && minutesLesson < minutesNextFinish &&
  //       ((hoursNextBegin < hoursLesson) || (hoursNextBegin === hoursLesson && minutesNextBegin < minutesLesson)))) {
  //     return  true; }
  //   return false;
  // }

}
