import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../services/AuthService';

@Injectable()
export  class AuthGuard implements CanActivate {

  constructor(private router: Router, private authService: AuthService) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!(this.authService.getToken() && this.authService.getToken() !== '')) {
      this.router.navigate(['signin']);
    } else {
      this.authService.resolveAuthorised(true);
    }
    return localStorage.getItem('token') && localStorage.getItem('token') !== '';
  }
}
