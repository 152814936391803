import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

@Injectable()
export class AuthService {
  public isAuthorised: Promise<boolean> = new Promise<boolean>(res => this.resolveAuthorised = res);
  resolveAuthorised: Function;
  constructor(private router: Router) {}

  setToken(token: string) {
    localStorage.setItem('token', token);
    this.isAuthorised = new Promise<boolean>(res => this.resolveAuthorised = res);
    if (token && token !== '') {this.resolveAuthorised(true); } else { this.resolveAuthorised(false); }
  }

  getToken() {
   return  localStorage.getItem('token');
  }

  logOut() {
    this.router.navigate(['signin']);
    localStorage.setItem('token', '');
    localStorage.setItem('lessons', '');
    localStorage.setItem('employee', '');
    localStorage.setItem('amountOfLessons', '');
    this.isAuthorised = new Promise<boolean>(res => this.resolveAuthorised = res);
    this.resolveAuthorised(false);
  }
}
