import {Injectable} from '@angular/core';
import {Lesson} from '../model/Lesson';
import {Subject} from 'rxjs';
import {HttpService} from './http-service';
import {AuthService} from './AuthService';

@Injectable()
export class ScheduleService {
  public lessons: Lesson[];
  public lessonsChanged = new Subject();
  constructor(private http: HttpService, private authService: AuthService) {}

  getLessons(): Lesson[] {
    return JSON.parse(localStorage.getItem('lessons'));
  }
  setLessons(lessons: Lesson[]) {
    this.lessons = lessons;
    localStorage.setItem('lessons', JSON.stringify(this.lessons));
    this.lessonsChanged.next();
  }

  loadSchedule(tutorID: string ) {
    this.http.getLessons(tutorID).subscribe(data => {
      this.setLessons(data);
      const now = new Date();
      now.setHours(0);
      now.setMinutes(0);
      now.setSeconds(0);
      let amountOfTodayLessons = 0;
      data.forEach(next => {
        const arr = next.date.split('-');
        const date = new Date(next.date);
        date.setFullYear(Number(arr[0]));
        date.setMonth(Number(arr[1]));
        date.setDate(Number(arr[2]));
        if (date.getDate() === now.getDate() && date.getMonth() === now.getMonth() && date.getFullYear() ===
          now.getFullYear()) {
          amountOfTodayLessons += 1;
        }
      });
      localStorage.setItem('amountOfLessons', amountOfTodayLessons.toString());
    }, () => {
      this.authService.logOut();
    });
}
}
