import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {API} from '../const';
import {map} from 'rxjs/operators';
import {Lesson} from '../model/Lesson';
import {Observable} from 'rxjs';
import {AuthService} from './AuthService';
import {Employee} from '../model/Employee';

@Injectable()
export class HttpService {
  constructor(private http: HttpClient, private authService: AuthService) {}

  signin(email: string, password: string) {
    return  this.http.post(API + '/api/authenticate', {email, password});
  }

  signinWithID(id: string, password: string) {
    return  this.http.post(API + '/api/authenticate', {id, password});
  }

  getUser(email: string, password: string) {
    const token = this.authService.getToken();
    return  this.http.post(API + '/employee/signin', {email, password}, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  getUserByID(tutorID: string, password: string) {
    const token = this.authService.getToken();
    return  this.http.post(API + '/employee/signinWithID', {tutorID, password}, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  getLessons(id: string): Observable<Lesson[]> {
    const token = this.authService.getToken();
    return  this.http.get(API + '/schedule/lessons/' + id, {
     headers: {
       'Authorization': `Bearer ${token}`
     }}).pipe(map((data: Lesson[]) => {
      return data;
    }));
  }

  addLesson(lesson: Lesson ) {
    const token = this.authService.getToken();
   return  this.http.post(API + '/schedule/lessons', lesson, {
     headers: {
       'Authorization': `Bearer ${token}`
     }}
     );
  }

  deleteLesson(id: string) {
    const token = this.authService.getToken();
    return this.http.delete(API + '/schedule/lessons/' + id, {
      headers: {
        'Authorization': `Bearer ${token}`
      }});
  }

  editLesson(lesson: Lesson) {
    const token = this.authService.getToken();
    return this.http.put(API + '/schedule/lessons/', lesson, {
      headers: {
        'Authorization': `Bearer ${token}`
      }});
  }

  recoverPassword(email: string) {
    return this.http.post(API + '/employee/recover', email);
  }


  changePassword(employee: Employee) {
    const token = this.authService.getToken();
     return this.http.post(API + '/employee/changePassword', employee, {
      headers: {
        'Authorization': `Bearer ${token}`
      }});
  }
  //
  // setLoad(employee: Employee) {
  //   const token = this.authService.getToken();
  //   return  this.http.put(API + '/employee/setLoad', employee, {
  //     headers: {
  //       'Authorization': `Bearer ${token}`
  //     }});
  // }

  updateUser(emloyee: Employee) {
    const token = this.authService.getToken();
    return  this.http.put(API + '/employee/updateUser', emloyee, {
      headers: {
        'Authorization': `Bearer ${token}`
      }});
  }
}
