import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Employee} from '../model/Employee';
import {HttpService} from '../services/http-service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EmployeeService} from '../services/employee-service';
import {Observable} from 'rxjs';
import {Lesson} from '../model/Lesson';
import {ScheduleService} from '../services/schedule-service';
import {AuthService} from '../services/AuthService';

@Component({
  selector: 'app-greeting',
  templateUrl: './greeting.component.html',
  styleUrls: ['./greeting.component.css']
})
export class GreetingComponent implements OnInit {
   employee: Employee;
  tutorID: string;
  lessons: Lesson[];
  lessonsAmount: any;
  amountOfLessons: Promise<number> = new Promise<number>((res) => {this.resAmountOfPromise = res; });
  private resAmountOfPromise: Function | null;
  message: Array<{}> = [];

  constructor(private http: HttpService, private employeeService: EmployeeService,
              private router: Router, private scheduleService: ScheduleService, private authService: AuthService) {
  }

  ngOnInit() {
    this.employee = JSON.parse(localStorage.getItem('employee'));
    if (!this.employee) {
      this.router.navigate(['signin']);
    }
    if (!this.employee.totalNumberOfLessons) {
      this.employee.totalNumberOfLessons = '?';
  }
    if (this.employee.notification) {
      this.message.push({severity: 'warn', summary: 'Напоминание', detail: this.employee.notification});

    }
    this.tutorID = this.employee.tutorID;
    const tmpLessons = localStorage.getItem('lessons');
    const tmpAmount = localStorage.getItem('amountOfLessons');
    if (tmpLessons) { this.lessons = JSON.parse(localStorage.getItem('lessons')); }
    if (tmpAmount) { this.lessonsAmount = Number(localStorage.getItem('amountOfLessons')); }
   if (!this.lessons || !this.lessonsAmount) {
     this.http.getLessons(this.tutorID).subscribe(data => {
       this.scheduleService.setLessons(data);
       const now = new Date();
       now.setHours(0);
       now.setMinutes(0);
       now.setSeconds(0);
       let amountOfTodayLessons = 0;
       data.forEach(next => {
         const arr = next.date.split('-');
         const date = new Date(next.date);
         date.setFullYear(Number(arr[0]));
         date.setMonth(Number(arr[1]) - 1);
         date.setDate(Number(arr[2]));
         if (date.getDate() === now.getDate() && date.getMonth() === now.getMonth() && date.getFullYear() ===
           now.getFullYear()) {
           amountOfTodayLessons += 1;
         }
       });
       localStorage.setItem('amountOfLessons', amountOfTodayLessons.toString());
       this.resAmountOfPromise(Number(localStorage.getItem('amountOfLessons')));
     }, () => {
       this.authService.logOut();
     });
     } else {
     this.resAmountOfPromise(Number(localStorage.getItem('amountOfLessons')));
   }
   }

  toSchedule() {
    this.router.navigate(['schedule']);
  }


}
