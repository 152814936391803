import { Component, OnInit } from '@angular/core';
import {AuthService} from '../services/AuthService';
import {HttpService} from '../services/http-service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  constructor(public authService: AuthService, private router: Router) { }

  ngOnInit() {
  }

  logOut() {
    this.authService.logOut();
  }

  changePassword() {
    this.router.navigate(['change-password']);
  }

  help() {
  }
}
