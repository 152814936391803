import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpService} from '../services/http-service';
import {Employee} from '../model/Employee';
import {Router} from '@angular/router';
import {EmployeeService} from '../services/employee-service';
import {AuthService} from '../services/AuthService';

import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {
  userForm: FormGroup;

  constructor(private http: HttpService, private router: Router, private employeeService: EmployeeService,
              private authService: AuthService, private messageService: MessageService) { }

  ngOnInit() {
    this.userForm = new FormGroup({
      email: new FormControl(null, [Validators.email, Validators.required]),
      password: new FormControl(null, [Validators.minLength(6), Validators.required])
    });
  }


  signin() {
    this.authService.setToken('');
    if (String(this.userForm.value['email']).includes('@')) {
      this.http.signin(this.userForm.value['email'], this.userForm.value['password']).subscribe((data: any) => {
        this.authService.setToken(data.token);
        this.http.getUser(this.userForm.value['email'], this.userForm.value['password']).subscribe(user => {
          if (user[0] != null) {
            const employee: Employee = user[0];
            this.employeeService.setEmployee(employee);
            this.router.navigate(['greeting']);
          }
        });
      }, (error) => {
        if (error.status === 0) {
          this.messageService.add(
            {
              severity: 'error',
              summary: 'Ошибка',
              detail: 'Проверьте подключение к интернету.'
            });
        }
        if (error.status === 403) {
          this.messageService.add(
            {
              severity: 'error',
              summary: 'Ошибка',
              detail: 'Ошибка авторизации'
            });
        }
      });
    } else {
      this.http.signinWithID(this.userForm.value['email'], this.userForm.value['password']).subscribe((data: any) => {
        this.authService.setToken(data.token);
        this.http.getUserByID(this.userForm.value['email'], this.userForm.value['password']).subscribe(user => {
          if (user[0] != null) {
            const employee: Employee = user[0];
            this.employeeService.setEmployee(employee);
            this.router.navigate(['greeting']);
          }
        });
      }, (error) => {
        if (error.status === 0) {
          this.messageService.add(
            {
              severity: 'error',
              summary: 'Ошибка',
              detail: 'Проверьте подключение к интернету.'
            });
        }
        if (error.status === 403) {
          this.messageService.add(
            {
              severity: 'error',
              summary: 'Ошибка',
              detail: 'Ошибка авторизации'
            });
        }
      });
    }
  }

  recoverPassword() {
    this.router.navigate(['recover-password']);
  }
}
