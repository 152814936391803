import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { SigninComponent } from './signin/signin.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HttpClientXsrfModule} from '@angular/common/http';
import {HttpService} from './services/http-service';
import { ScheduleComponent } from './schedule/schedule.component';
import {AppRoutingModule} from './app.routing.module';
import {EditLessonComponent} from './popup/edit-lesson/edit-lesson.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { MyDatePickerModule } from 'mydatepicker';
import {HomeComponent} from './home/home.component';
import {GreetingComponent} from './greeting/greeting.component';
import {EmployeeService} from './services/employee-service';
import {ScheduleService} from './services/schedule-service';
import {AngularFontAwesomeModule} from 'angular-font-awesome';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import {
  CalendarModule,
  CardModule,
  ConfirmationService,
  ConfirmDialogModule, DialogModule,
  DropdownModule, InputTextareaModule,
  MessageService, MessagesModule, TabViewModule,
  ToolbarModule, TooltipModule
} from 'primeng/primeng';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthService} from './services/AuthService';
import {RecoverPasswordComponent} from './recover-password/recover-password.component';
import {ChangePasswordComponent} from './change-password/change-password.component';
import {ToastModule} from 'primeng/toast';
import {ConflictService} from './services/conflict-service';
import {MatInputModule} from '@angular/material';
import { TextMaskModule } from 'angular2-text-mask';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    HeaderComponent,
    FooterComponent,
    ScheduleComponent,
    EditLessonComponent,
    HomeComponent,
    GreetingComponent,
    RecoverPasswordComponent,
    ChangePasswordComponent
  ],
  imports: [
    BrowserModule,
    TextMaskModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule.forRoot(),
    MyDatePickerModule,
    AngularFontAwesomeModule,
    CalendarModule,
    BrowserAnimationsModule,
    DropdownModule,
    FormsModule,
    NgxMaskModule.forRoot(),
    ToastModule,
    ConfirmDialogModule,
    ToolbarModule,
    CardModule,
    MatInputModule,
    TabViewModule,
    InputTextareaModule,
    MessagesModule,
    TooltipModule,
    DialogModule
  ],
  providers: [HttpService, EmployeeService, ScheduleService, AuthService,
    ConfirmationService, MessageService, ConflictService, {provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent],
  entryComponents: [EditLessonComponent]
})
export class AppModule { }
