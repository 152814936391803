import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpService} from '../services/http-service';
import {Router} from '@angular/router';
import {EmployeeService} from '../services/employee-service';
import {Employee} from '../model/Employee';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  userForm: FormGroup;
  loadForm: FormGroup;
  employee: Employee;
  lastUpdate: Promise<string> = new Promise<string>(res => this.resLastUpdate = res);
  resLastUpdate: (value?: (PromiseLike<string> | string)) => void;

  constructor(private http: HttpService, private router: Router, private employeeService: EmployeeService, private messageService: MessageService) { }

  ngOnInit() {
    const employee: Employee = this.employeeService.getEmployee();
    this.employee = employee;
    if (this.employee.lastUpdate) {
      const lastUpdate = this.getDateToString(this.employee.lastUpdate);
      this.lastUpdate = new Promise<string>(res => this.resLastUpdate = res);
      this.resLastUpdate(lastUpdate);
    }
    this.userForm = new FormGroup({
      password: new FormControl(null, [Validators.minLength(6), Validators.required]),
      passwordNew: new FormControl(null, [Validators.minLength(6), Validators.required]),
      passwordNewRepeat: new FormControl(null, [Validators.minLength(6), Validators.required])
    });
    this.loadForm = new FormGroup({
      hours: new FormControl(employee.load, [Validators.required, Validators.pattern('^[0-9.]+$')]),
      groups: new FormControl( employee.interestGroups),
      preferences: new FormControl( employee.preferences),
      comments: new FormControl( employee.comments),
      currentScheduledLoadWeekly: new FormControl(employee.currentScheduledLoadWeekly, [Validators.required, Validators.pattern('^[0-9.]+$')])
    });
  }


  changePassword() {
    const employee: Employee = this.employeeService.getEmployee();
    if (this.userForm.value['password'] !== employee.password) {
      this.messageService.add(
        {severity: 'error',
          summary: 'Ошибка',
          detail: 'Старый пароль введен неверно!'});
    } else {
      if (this.userForm.value['passwordNew'] === this.userForm.value['passwordNewRepeat']) {
        employee.password = this.userForm.value['passwordNew'];
        this.http.changePassword(employee).subscribe((data: any) => {
          if (data.status === 'OK') {
            this.employeeService.setEmployee(employee);
            this.messageService.add(
              {
                severity: 'success',
                summary: '',
                detail: 'Пароль успешно изменен'
              });
          } else {
            this.messageService.add(
              {
                severity: 'error',
                summary: 'Ошибка',
                detail: 'Ошибка изменения пароля.'
              });
          }
        }, error => {
          if (error.status === 0) {
            this.messageService.add(
              {
                severity: 'error',
                summary: 'Ошибка',
                detail: 'Проверьте подключение к интернету.'
              });
          } else {
            this.messageService.add(
              {
                severity: 'error',
                summary: 'Ошибка',
                detail: 'Ошибка изменения пароля.'
              });
          }
        });

      } else {
        this.messageService.add(
          {
            severity: 'error',
            summary: 'Ошибка',
            detail: 'Пароли не совпадают.'
          });
      }
    }
  }

  getDateToString(lastUpdate: string) {
    if (lastUpdate) {
      const date = lastUpdate.split('-');
      if (date[1].length < 2) {
        date[1] = '0' + date [1];
      }
      if (date[0].length < 2) {
        date[0] = '0' + date[0];
      }
      return date.join('.');
    }
  }

  goToHome() {
    this.router.navigate(['greeting']);
  }

  setLoad() {
    const employee: Employee = this.employeeService.getEmployee();
    employee.load = this.loadForm.value['hours'];
    if (this.loadForm.value['groups']) {
      employee.interestGroups = this.loadForm.value['groups'];
    } else {
      employee.interestGroups = 'false' ;
    }
    employee.preferences = this.loadForm.value['preferences'];
    employee.comments = this.loadForm.value['comments'];
    employee.currentScheduledLoadWeekly = this.loadForm.value['currentScheduledLoadWeekly'];
    const nowDate = new Date();
    employee.lastUpdate = nowDate.getDate() + '-' + (nowDate.getMonth() + 1) + '-' + nowDate.getFullYear();
    this.http.updateUser(employee).subscribe((data: any) => {
      if (data.status === 'OK') {
        this.employeeService.setEmployee(employee);
        this.messageService.add(
          {
            severity: 'success',
            summary: 'Success Message',
            detail: 'Введенные данные сохранены!'
          });
      }

      const lastUpdate = this.getDateToString(employee.lastUpdate);
      this.employee = employee;
      this.lastUpdate = new Promise<string>(res => this.resLastUpdate = res);
      this.resLastUpdate(lastUpdate);
    }, error => {
      this.messageService.add(
        {
          severity: 'error',
          summary: 'Error Message',
          detail: 'Ошибка изменения данных'
        });
    });
  }
}
