import {Component, OnInit} from '@angular/core';
import {HttpService} from './services/http-service';
import {Lesson} from './model/Lesson';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'phystech-front';

  constructor() {}
  ngOnInit() {

  }
}
