import { Component, OnInit } from '@angular/core';
import {HttpService} from '../services/http-service';
import {Router} from '@angular/router';
import {EmployeeService} from '../services/employee-service';
import {AuthService} from '../services/AuthService';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Employee} from '../model/Employee';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.css']
})
export class RecoverPasswordComponent implements OnInit {

  constructor(private http: HttpService, private router: Router, private employeeService: EmployeeService,
              private authService: AuthService, private messageService: MessageService) { }
  userForm: FormGroup;

  ngOnInit() {
    this.userForm = new FormGroup({
      email: new FormControl(null, [Validators.email, Validators.required])
    });
  }


  recover() {
    this.authService.setToken('');
    this.http.recoverPassword(this.userForm.value['email']).subscribe((data: any) => {
      if (data.status === 'OK') {
        this.messageService.add(
          {severity: 'success',
          summary: 'Восстановление пароля',
          detail: 'Инструкции по восстановлению пароля отправлены на почту.'});
      } else {
        this.messageService.add({severity: 'error',
          summary: 'Ошибка',
          detail: 'Проверьте правильность написания почты.'});
      }
    }, error => {
      if (error.status === 0) {
        this.messageService.add({
          severity: 'error',
          summary: 'Ошибка',
          detail: 'Проверьте подключение к интернету.'
        });
      }
      if (error.status === 403) {
        this.messageService.add({
          severity: 'error',
          summary: 'Ошибка',
          detail: 'Возникла ошибка. Проверьте правильность написания почты.'
        });
      }
    });
  }

  goToSignIn() {
    this.router.navigate(['signin']);
  }
}
