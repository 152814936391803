import {Component, Injectable, Input, OnInit} from '@angular/core';
import {HttpService} from '../services/http-service';
import {ActivatedRoute, Router} from '@angular/router';
import {Lesson} from '../model/Lesson';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EditLessonComponent} from '../popup/edit-lesson/edit-lesson.component';
import {Employee} from '../model/Employee';
import {Observable} from 'rxjs';
import {ScheduleService} from '../services/schedule-service';
import {EmployeeService} from '../services/employee-service';
import {AuthService} from '../services/AuthService';
import {ConfirmationService, Message, MessageService} from 'primeng/api';
import {ConflictService} from '../services/conflict-service';


@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css']
})
@Injectable()
export class ScheduleComponent implements OnInit {
  tutorID: string;
  lessonsPromise: Promise<any[]> = new Promise<any[]>((resolve) => this.resolveLessons = resolve);
  lessons: Lesson[];
  todayLessons: any[] = [];
  todayLessonsCopy: Lesson[] = [];
  employee: Employee;
  resolveLessons: Function | null;
  nowDate = new Date();
  message: Array<{}> = [];
  isAllPromise: Promise<boolean> = new Promise<boolean>((resolve) => this.isAllResolve = resolve);
  isAllResolve: Function;
  displayWarningLastUpdate = false;


  constructor(private http: HttpService, private dialog: NgbModal, private router: Router,
    private scheduleService: ScheduleService, private employeeService: EmployeeService,
              private messageService: MessageService, private conflictService: ConflictService,
              private confirmationService: ConfirmationService, private authService: AuthService) {
  }

  ngOnInit() {
    this.employee = this.employeeService.getEmployee();
    if (this.employee.notification) {
      this.message.push({severity: 'warn', summary: 'Напоминание', detail: this.employee.notification});

    }
    this.updateLessons();
    this.update();
    this.scheduleService.lessonsChanged.subscribe(() => {
      this.updateLessons();
    });
  }

  updateLessons() {
    this.isAllPromise = new Promise<boolean>((resolve) => this.isAllResolve = resolve);
    this.isAllResolve(false);
    this.todayLessonsCopy = [];
    this.todayLessons = [];
    this.lessons = this.scheduleService.getLessons();
    this.nowDate = new Date();
    this.nowDate.setHours(0);
    this.nowDate.setMinutes(0);
    this.nowDate.setSeconds(0);

    this.lessons.forEach(next => {
      const options = {
        month: 'long',
        day: 'numeric',
        weekday: 'long'
      };
      let arr = next.date.split('-');
      const date = new Date(next.date);
        date.setFullYear(Number(arr[0]));
        date.setMonth(Number(arr[1]) - 1);
        date.setDate(Number(arr[2]));
      const dateForSorting = new Date(next.date);
      dateForSorting.setFullYear(Number(arr[0]));
      dateForSorting.setMonth(Number(arr[1]) - 1);
      dateForSorting.setDate(Number(arr[2]));

      let dateString =  date.toLocaleString('ru', options);
      arr = dateString.split(' ');
      arr[0] = arr[0].replace(',', '');
      if (arr[0] === 'понедельник') { arr[0] = 'Пн'; }
      if (arr[0] === 'вторник') { arr[0] = 'Вт'; }
      if (arr[0] === 'среда') { arr[0] = 'Ср'; }
      if (arr[0] === 'четверг') { arr[0] = 'Чт'; }
      if (arr[0] === 'пятница') { arr[0] = 'Пт'; }
      if (arr[0] === 'суббота') { arr[0] = 'Сб'; }
      if (arr[0] === 'воскресенье') { arr[0] = 'Вс'; }

      dateString = arr[0] + ', ' + arr[1] + ' ' + arr[2];

      if ((date > this.nowDate) || (date.getDate() === this.nowDate.getDate() &&
        date.getMonth() === this.nowDate.getMonth() &&
          date.getFullYear() === this.nowDate.getFullYear()
      )) {
        const lessonTmp: {id: string, studentOrGroup: any, format: any, date: any,
          timeForDisplay: any, subject: any, regularity: any, auditory: any; dateForSorting: Date;  tutorID: string,
          duration: string, time: string, remarks: string} =  {id: next.id,
          studentOrGroup: next.studentOrGroup,
          format: next.format, date: dateString, timeForDisplay: this.getTimeString(next.time, next.duration), subject: next.subject,
          regularity: next.regularity, auditory: next.auditory, dateForSorting: dateForSorting, tutorID: next.tutorID,
          duration: next.duration, time: next.time,  remarks: next.remarks};
        this.todayLessons.push(lessonTmp);
      }
    });

   this.todayLessons.sort((a, b) => {
     if (a.dateForSorting > b.dateForSorting) {
       return 1; }
     if (a.dateForSorting < b.dateForSorting) {
       return -1; }
     return 0;
    });
    this.todayLessons.sort((a, b) => this.compareTimes(a, b));
   this.todayLessonsCopy = JSON.parse(JSON.stringify(this.todayLessons));

    this.lessonsPromise = new Promise<Lesson[]>((resolve) => this.resolveLessons = resolve);
    this.resolveLessons(this.todayLessons);

    this.tutorID = this.employee.tutorID;
    if (!this.employee) {
      this.router.navigate(['signin']);
    }
  }


  compareTimes(a: any, b: any): number {
    if (a.dateForSorting.getDate() === b.dateForSorting.getDate() &&
    a.dateForSorting.getMonth() === b.dateForSorting.getMonth() &&
    a.dateForSorting.getFullYear() === b.dateForSorting.getFullYear()) {
      const arrA: string[] = a.time.split(':');
      const hoursA: number = Number(arrA[0]);
      let minutesAStr: string = arrA[1];
      minutesAStr = '0.' + minutesAStr;
      const minutesA = Number(minutesAStr);

      const arrB: string[] = b.time.split(':');
      const hoursB: number = Number(arrB[0]);
      let minutesBStr: string = arrB[1];
      minutesBStr = '0.' + minutesBStr;
      const minutesB = Number(minutesBStr);

      const aTime: number = hoursA + minutesA;
      const bTime: number = hoursB + minutesB;

      if (aTime > bTime) {
        return 1;
      }
      if (aTime < bTime) {
        return -1;
      }
      return 0;
    }
    return 0;
  }


  delete(lesson: Lesson) {
    if (!this.isLastUpdateBiggerThanMonthAgo()) {
    this.confirmationService.confirm({
      message: 'Вы уверены, что хотите удалить это занятие?',
      header: 'Удаление',
      acceptLabel: 'Да',
      rejectLabel: 'Нет',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.http.deleteLesson(lesson.id).subscribe((data) => {
          if (data['status'] === 'OK') {
            this.scheduleService.loadSchedule(this.tutorID);
            // this.http.getLessons(this.tutorID).subscribe(lessons => {
            //   this.scheduleService.setLessons(lessons);
            // });
            this.messageService.add(
              {severity: 'success',
                summary: '',
                detail: 'Занятие успешно удалено.'});
          }
        }, () => {
          this.authService.logOut();
          });
      },
      reject: () => {
      }
    });
    this.http.getUser(this.employee.email, this.employee.password).subscribe(user => {
      if (user[0] != null) {
        const employee: Employee = user[0];
        this.employeeService.setEmployee(employee);
      }
    });
    } else {
      this.displayWarningLastUpdate = true;
    }
  }

  isLastUpdateBiggerThanMonthAgo() {
   if(this.employee.lastUpdate) {
     const dateChunk = this.employee.lastUpdate.split('-');
     const lastUpdate = new Date();
     lastUpdate.setFullYear(dateChunk[2]);
     lastUpdate.setMonth(dateChunk[1]);
     lastUpdate.setDate(dateChunk[0]);
     const nowDate = new Date();
     console.log(lastUpdate, nowDate);
     if (lastUpdate < nowDate) {
       return true;
     }
   }
    return false;
  }


  edit(lesson: Lesson, i: number) {
    if (!this.isLastUpdateBiggerThanMonthAgo()) {
      const copy: Lesson = JSON.parse(JSON.stringify(lesson));
      copy.date = this.todayLessons[i].dateForSorting;
      const modalRef = this.dialog.open(EditLessonComponent);
      modalRef.componentInstance.lesson = copy;
      modalRef.componentInstance.isEdit = true;
    } else {
      this.displayWarningLastUpdate = true;
    }
  }

  addLesson() {
    if (!this.isLastUpdateBiggerThanMonthAgo()) {
      const modalRef = this.dialog.open(EditLessonComponent);
      modalRef.componentInstance.lesson = new Lesson(null, '', '', '', '', '', '', '', '', '', '', '');
      modalRef.componentInstance.isEdit = false;
    } else {
      this.displayWarningLastUpdate = true;
    }
  }

  getTimeString(time: string, duration: string) {
    const arr: string[] = time.split(':');
    const hours: number = Number(arr[0]);
    const minutes: number = Number(arr[1]);
    const durationArr = duration.split('.');
    const hoursDuration = Number(durationArr[0]);
    let minutesDuration = Number(durationArr[1]);
    if (minutesDuration === 5) {
      minutesDuration = 30;
    }
    let sumHours = hours + hoursDuration;
    let sumMinutes = (minutes + minutesDuration);
    if (sumMinutes >= 60 ) {
      sumMinutes = sumMinutes - 60;
      sumHours += 1;
    }
    if (sumHours >= 24) { sumHours = sumHours - 24; }
    const resMinutes = sumMinutes < 10 ? '0' + sumMinutes : '' + sumMinutes;
    const resHours = sumHours < 10 ? '0' + sumHours : '' + sumHours;
    const timeString = time + '-' + resHours + ':' + resMinutes;
   return timeString;
}

  dublicate(i: number) {
    if (!this.isLastUpdateBiggerThanMonthAgo()) {
      const lessonToCopy: Lesson = this.todayLessonsCopy[i];
      const date: Date = this.todayLessons[i].dateForSorting;
      date.setDate(date.getDate() + 7);
      let monthString = (date.getMonth() + 1).toString();
      let dayString = date.getDate().toString();
      if (monthString.length < 2) {
        monthString = '0' + monthString;
      }
      if (dayString.length < 2) {
        dayString = '0' + dayString;
      }
      const dateString = date.getFullYear() + '-' + monthString + '-' + dayString;
      lessonToCopy.date = dateString;
      lessonToCopy.id = null;
      lessonToCopy.remarks = '';
      let isConflictExist = false;
      const allLessons = this.scheduleService.getLessons();
      allLessons.forEach(next => {
        if (this.conflictService.hasConflict(next, lessonToCopy)) {
          this.messageService.add({severity: 'error', summary: 'Error Message', detail: 'На текущее время уже добавлено занятие!'});
          isConflictExist = true;
        }
      });
      if (!isConflictExist) {
        lessonToCopy.tutorName = this.employee.tutorName;
        this.http.addLesson(lessonToCopy).subscribe(data => {
          if (data['status'] === 'OK') {
            this.http.getLessons(this.employee.tutorID).subscribe(lessons => {
              this.scheduleService.setLessons(lessons);
            });
          }
        }, () => {
          this.authService.logOut();
        });
      }
      this.http.getUser(this.employee.email, this.employee.password).subscribe(user => {
        if (user[0] != null) {
          const employee: Employee = user[0];
          this.employeeService.setEmployee(employee);
        }
      });
    } else {
      this.displayWarningLastUpdate = true;
    }
  }

  goToHome() {
    this.router.navigate(['greeting']);
  }

  update() {
    this.http.getLessons(this.tutorID).subscribe(data => {
      this.scheduleService.setLessons(data);
      const now = new Date();
      now.setHours(0);
      now.setMinutes(0);
      now.setSeconds(0);
      let amountOfTodayLessons = 0;
      data.forEach(next => {
        const arr = next.date.split('-');
        const date = new Date(next.date);
        date.setFullYear(Number(arr[0]));
        date.setMonth(Number(arr[1]) - 1);
        date.setDate(Number(arr[2]));
        if (date.getDate() === now.getDate() && date.getMonth() === now.getMonth() && date.getFullYear() ===
          now.getFullYear()) {
          amountOfTodayLessons += 1;
        }
      });
      localStorage.setItem('amountOfLessons', amountOfTodayLessons.toString());
  }, () => {
      this.authService.logOut();
    });
    this.http.getUser(this.employee.email, this.employee.password).subscribe(user => {
      if (user[0] != null) {
        const employee: Employee = user[0];
        this.employeeService.setEmployee(employee);
      }
    });
  }

  showAllLessons() {
    this.isAllPromise = new Promise<boolean>((resolve) => this.isAllResolve = resolve);
    this.isAllResolve(true);
    this.todayLessonsCopy = [];
    this.todayLessons = [];
    this.lessons = this.scheduleService.getLessons();

    this.nowDate = new Date();
    this.nowDate.setHours(0);
    this.nowDate.setMinutes(0);
    this.nowDate.setSeconds(0);
    this.nowDate.setDate(this.nowDate.getDate() - 7);

    this.lessons.forEach(next => {
      const options = {
        month: 'long',
        day: 'numeric',
        weekday: 'long'
      };
      let arr = next.date.split('-');
      const date = new Date(next.date);
      date.setFullYear(Number(arr[0]));
      date.setMonth(Number(arr[1]) - 1);
      date.setDate(Number(arr[2]));
      const dateForSorting = new Date(next.date);
      dateForSorting.setFullYear(Number(arr[0]));
      dateForSorting.setMonth(Number(arr[1]) - 1);
      dateForSorting.setDate(Number(arr[2]));

      let dateString =  date.toLocaleString('ru', options);
      arr = dateString.split(' ');
      arr[0] = arr[0].replace(',', '');
      if (arr[0] === 'понедельник') { arr[0] = 'Пн'; }
      if (arr[0] === 'вторник') { arr[0] = 'Вт'; }
      if (arr[0] === 'среда') { arr[0] = 'Ср'; }
      if (arr[0] === 'четверг') { arr[0] = 'Чт'; }
      if (arr[0] === 'пятница') { arr[0] = 'Пт'; }
      if (arr[0] === 'суббота') { arr[0] = 'Сб'; }
      if (arr[0] === 'воскресенье') { arr[0] = 'Вс'; }

      dateString = arr[0] + ', ' + arr[1] + ' ' + arr[2];

      if ((date > this.nowDate) || (date.getDate() === this.nowDate.getDate() &&
        date.getMonth() === this.nowDate.getMonth() &&
        date.getFullYear() === this.nowDate.getFullYear()
      )) {
        const lessonTmp: {id: string, studentOrGroup: any, format: any, date: any,
          timeForDisplay: any, subject: any, regularity: any, auditory: any; dateForSorting: Date;  tutorID: string,
          duration: string, time: string, remarks: string} =  {id: next.id,
          studentOrGroup: next.studentOrGroup,
          format: next.format, date: dateString, timeForDisplay: this.getTimeString(next.time, next.duration), subject: next.subject,
          regularity: next.regularity, auditory: next.auditory, dateForSorting: dateForSorting, tutorID: next.tutorID,
          duration: next.duration, time: next.time,  remarks: next.remarks};
        this.todayLessons.push(lessonTmp);
      }
    });

    this.todayLessons.sort((a, b) => {
      if (a.dateForSorting > b.dateForSorting) {
        return 1; }
      if (a.dateForSorting < b.dateForSorting) {
        return -1; }
      return 0;
    });
    this.todayLessons.sort((a, b) => this.compareTimes(a, b));
    this.todayLessonsCopy = JSON.parse(JSON.stringify(this.todayLessons));

    this.lessonsPromise = new Promise<Lesson[]>((resolve) => this.resolveLessons = resolve);
    this.resolveLessons(this.todayLessons);

    this.tutorID = this.employee.tutorID;
    if (!this.employee) {
      this.router.navigate(['signin']);
    }

    this.nowDate = new Date();
  }

  navigateToSettings() {
    this.router.navigate(['change-password']);
  }
}
